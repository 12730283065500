.main {
  position: relative;
  display: flex;
  width: calc(100% - var(--sidebarWidth));
  height: 100%;
  min-height: 100vh;
  margin-left: var(--sidebarWidth);
  padding-bottom: var(--footerHeight);
  background: var(--mainBg);
  transition: .3s width ease-in-out, .3s margin-left ease-in-out;

  &--is-extended {
    width: calc(100% - var(--sidebarToolsWidth));
    margin-left: var(--sidebarToolsWidth);
  }

  &-container {
    padding: 30px 30px 10px 30px;
  }

  &__no-support {
    &__text {
      margin: 50px;
      text-align: center;
      font-size: $xl2;
      font-weight: $bold;
      color: var(--themeCorporate500);
    }
  }
}