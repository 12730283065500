.btn {
  border: 2px solid var(--themeCorporate500);
  border-radius: 100px;
  padding: 18px 60px;
  font-size: $base;
  font-weight: $bold;
  color: var(--white);
  background-color: var(--themeCorporate500);

  &:hover {
    color: var(--themeCorporate500);
    background-color: var(--white);
  }
}