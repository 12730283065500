$crowdProgressWidth: 190px;
$crowdStatusWidth: 140px;
$crowdDeadlineWidth: 140px;

.crowdfunding-projects {
  &__description {
    margin: 0 0 20px 0;
  }

  &__table {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__head {
      flex-basis: calc((100% + #{$crowdProgressWidth} + #{$crowdStatusWidth} + #{$crowdDeadlineWidth}) / 7);
      border-bottom: 1px solid var(--themeGray100);
      padding: 6px;
      font-size: 10px;
      line-height: 13px;
      font-weight: $bold;
      text-transform: uppercase;
      color: var(--themeGray400);

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(5) {
        flex-basis: $crowdProgressWidth;
      }

      &:nth-child(6) {
        flex-basis: $crowdStatusWidth;
      }

      &:nth-child(7) {
        flex-basis: $crowdDeadlineWidth;
      }
    }
  }
}

.crowdfunding-project-item {
  border-bottom: 1px solid var(--themeGray100);

  &__cell {
    display: flex;
    align-items: center;
    flex-basis: calc((100% + #{$crowdProgressWidth} + #{$crowdStatusWidth} + #{$crowdDeadlineWidth}) / 7);
    height: 52px;
    padding: 8px 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: $sm;
    font-weight: $bold;
    line-height: 20px;
    color: var(--themeBaseBlack);

    &:nth-child(5) {
      flex-basis: $crowdProgressWidth;
    }

    &:nth-child(6) {
      flex-basis: $crowdStatusWidth;
    }

    &:nth-child(7) {
      flex-basis: $crowdDeadlineWidth;
    }

    &__name {
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &__top {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__bottom {
        font-size: 10px;
        font-weight: $regular;
        line-height: 13px;
      }
    }

    &__digits {
      display: block;
      width: 100%;
      text-align: center;
    }

    &__progress {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__bar {
        position: relative;
        height: 10px;
        width: 100%;
        overflow: hidden;
        margin: 5px 0 3px 0;
        border-radius: 29px;
        background-color: var(--themeGray100);
      }

      &__fill {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-radius: 29px;
        animation: 1s progressFilling linear;

        &--primary {
          background-color: var(--themeBlue700);
        }

        &--warning {
          background-color: var(--themeYellow500);
        }

        &--failed {
          background-color: var(--themeRed500);
        }

        &--success {
          background-color: var(--themeGreen500);
        }
      }

      &__percentage {
        font-size: 10px;
        line-height: 13px;

        &__value {
          font-weight: $bold;

          &--primary {
            color: var(--themeBlue700);
          }

          &--warning {
            color: var(--themeYellow500);
          }

          &--failed {
            color: var(--themeRed500);
          }

          &--success {
            color: var(--themeGreen500);
          }
        }

        &__text {
          margin-left: 5px;
          font-weight: $regular;
        }
      }
    }
  }

  &__skeleton-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    border-bottom: 1px solid var(--themeGray100);
    padding: 8px 5px;

    & > div {
      flex-basis: calc((100% + #{$crowdProgressWidth} + #{$crowdStatusWidth} + #{$crowdDeadlineWidth}) / 7);
      height: 100%;

      &:nth-child(5) {
        flex-basis: $crowdProgressWidth;
      }

      &:nth-child(6) {
        flex-basis: $crowdStatusWidth;
      }

      &:nth-child(7) {
        flex-basis: $crowdDeadlineWidth;
      }
    }

    &__name {
      &--wrapper {
        display: flex;
        flex-direction: column;
      }

      &--top {
        width: 95%;
        height: 18px;
        margin-bottom: 4px;
      }

      &--bottom {
        width: 70%;
        height: 15px;
      }
    }

    &__amount {
      &--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      width: 30%;
      height: 25px;
    }

    &__current,
    &__goal {
      &--wrapper {
        display: flex;
        align-items: center;
      }

      width: 80%;
      height: 25px;
    }

    &__progress {
      &--wrapper {
        display: flex;
        flex-direction: column;
      }

      &--top {
        width: 100%;
        height: 18px;
        margin-bottom: 4px;
      }

      &--bottom {
        width: 70%;
        height: 15px;
      }
    }

    &__status {
      width: 85%;
      height: 25px;
      margin: 6px 0 0 7px;
    }

    &__deadline {
      width: 90%;
      height: 20px;

      &--wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}