.login-remember {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__check {
    --dimensions: 16px;

    position: relative;
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    margin-right: 5px;
    border: 1px solid #9CA8BA;
    border-radius: 2px;

    &__icon {
      --dimensions: 14px;

      width: var(--dimensions);
      height: var(--dimensions);
      transform: scale(0);
      transition: .3s all ease-in-out;

      &--is-visible {
        transform: scale(1);
      }
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    color: var(--themeGray100);
  }
}